import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import './NotificationBanner.css';

const NotificationBanner = () => {
    const { user } = useContext(AuthContext); // Access the logged-in user
    const [hasActiveOrder, setHasActiveOrder] = useState(false);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const now = new Date();
    const day = now.getDay();
    const hour = now.getHours();

    const isWeekday = day >= 1 && day <= 5;
    const isSaturday = day === 10;

    const withinOrderingHours =
        (isWeekday && hour >= 8 && hour < 17) ||
        (isSaturday && hour >= 8 && hour < 14);

    const earlyOrderingHours =
        (isWeekday && hour >= 7 && hour < 8) ||
        (isSaturday && hour >= 7 && hour < 8);

    // Array of messages to rotate
    const messages = [
        withinOrderingHours
            ? 'We are currently open for orders! (7 AM to 5 PM, Monday to Friday).'
            : earlyOrderingHours
            ? 'Orders are open, but the kitchen opens at 8 AM for meal preparation.'
            : 'Sorry, we are currently closed. Please come back during our ordering hours (7 AM to 5 PM, Monday to Friday).',
            'FREE DELIVERY available in Tongaat, Seatides, Desainagar, Westbrook!',
    ];

    // Fetch user-specific active orders
    useEffect(() => {
        if (user) {
            const fetchUserOrders = async () => {
                try {
                    const res = await fetch(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/orders/user/${user.id}`);
                    const data = await res.json();
                    const activeOrders = data.filter(order => order.status !== 'Delivered');
                    setHasActiveOrder(activeOrders.length > 0);
                } catch (err) {
                    console.error('Error fetching user orders:', err);
                    setHasActiveOrder(false);
                }
            };
            fetchUserOrders();
        } else {
            setHasActiveOrder(false);
        }
    }, [user]);

    // Rotate between messages every 2 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 5000); // 2000ms = 2 seconds

        return () => clearInterval(interval); // Clear the interval on component unmount
    }, [messages.length]);

    return (
        <div className={`notification-banner ${withinOrderingHours || earlyOrderingHours ? 'banner-green' : 'banner-red'} banner-space`}>
            {messages[currentMessageIndex]} {/* Rotating message */}
            {user && hasActiveOrder && (
                <div className="track-order-container">
                    <div className="wording-container">Track your active order by clicking the button below!</div>
                    <Link to="/Orders">
                        <button className="ios-button-track">
                            Track My Order
                        </button>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default NotificationBanner;
